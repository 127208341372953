import React, { FC } from 'react';
import { DraggableStateSnapshot, DraggableProvided } from 'react-beautiful-dnd';
import { Card, TextInput } from 'evergreen-ui';

const EntryCard: FC<{
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}> = ({ provided, snapshot, onClick }) => {
  return (
    <Card
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
      marginY={8}
      padding={8}
      elevation={snapshot.isDragging ? 2 : 1}
      background="white"
      onClick={onClick}
    >
      Some Stuff
    </Card>
  );
};

export default EntryCard;
