import React, { useState, useCallback } from 'react';
import Amplify from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Pane } from 'evergreen-ui';
import '@aws-amplify/ui/dist/style.css';

import aws_exports from './aws-exports';
import Header from './components/Header';
import Week from './components/Week';
import Detail, { NewEntryData } from './components/Detail';

Amplify.configure(aws_exports);

function App() {
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [opened, setOpened] = useState<string>();
  const [newEntryData, setNewEntryData] = useState<NewEntryData>();
  const startNew = useCallback((data: NewEntryData) => {
    setOpened(undefined);
    setNewEntryData(data);
  }, []);
  const openExisting = useCallback((id: string) => {
    setNewEntryData(undefined);
    setOpened(id);
  }, []);

  return (
    <Pane display="flex" flexDirection="column" height="100%">
      <Header currentWeek={currentWeek} setCurrentWeek={setCurrentWeek} />
      <Week
        current={currentWeek}
        openExisting={openExisting}
        startNew={startNew}
      />
      <Detail id={opened} newEntryData={newEntryData} />
    </Pane>
  );
}

export default withAuthenticator(App, { usernameAlias: 'email' });
