import React, { FC, useState, useEffect } from 'react';
import {
  SideSheet,
  Pane,
  Heading,
  Paragraph,
  Card,
  TextInputField,
} from 'evergreen-ui';
import Editor from 'rich-markdown-editor';
import { Formik, Field, Form, useFormikContext, FieldProps } from 'formik';

export type NewEntryData = { title: string; date: string };

const Detail: FC<{ id?: string; newEntryData?: NewEntryData }> = ({
  id,
  newEntryData,
}) => {
  const [shown, setShown] = useState(false);
  useEffect(() => {
    (id || newEntryData) && setShown(true);
  }, [id, newEntryData]);

  // TODO: Proper type
  const [data, setData] = useState<NewEntryData>();

  const FormStateObserver = () => {
    // TODO: Proper type
    const { values } = useFormikContext<NewEntryData>();
    useEffect(() => {
      setData(values);
    }, [values]);

    return null;
  };

  return (
    <SideSheet
      isShown={shown}
      onCloseComplete={() => setShown(false)}
      containerProps={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
      }}
    >
      <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
        <Pane padding={16}>
          <Heading size={600}>{(data && data.title) || 'Untitled'}</Heading>
          <Paragraph size={400}>All changes saved</Paragraph>
        </Pane>
      </Pane>
      <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
        <Formik
          initialValues={newEntryData || {}}
          validationSchema={undefined}
          // TODO: Consider saving right away on submit
          onSubmit={() => {}}
        >
          <Form>
            <Field name="title">
              {({ field, meta }: FieldProps<string>) => (
                <TextInputField
                  label="Title"
                  {...field}
                  isInvalid={meta.touched && !!meta.error}
                  validationMessage={(meta.touched && meta.error) || undefined}
                  required
                />
              )}
            </Field>

            <Field name="date">
              {({ field, meta }: FieldProps<string>) => (
                <TextInputField
                  type="date"
                  label="Date"
                  {...field}
                  isInvalid={meta.touched && !!meta.error}
                  validationMessage={(meta.touched && meta.error) || undefined}
                  required
                />
              )}
            </Field>

            <FormStateObserver />
          </Form>
        </Formik>

        <Card
          backgroundColor="white"
          elevation={0}
          minHeight={96}
          display="flex"
          padding={32}
        >
          <Editor
            id="replaceme"
            placeholder="Optional notes..."
            headingsOffset={1}
            // TODO: Consider saving right away
            onSave={() => {}}
            onChange={changed => console.log(changed())}
          />
        </Card>
      </Pane>
    </SideSheet>
  );
};

export default Detail;
