import React, { FC, useCallback, useState } from 'react';
import {
  Pane,
  Heading,
  SearchInput,
  SegmentedControl,
  Button,
  IconButton,
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'evergreen-ui';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { subWeeks, addWeeks } from 'date-fns';

const Header: FC<{
  currentWeek: Date;
  setCurrentWeek: (week: Date) => void;
}> = ({ currentWeek, setCurrentWeek }) => {
  const onWeekChange = useCallback(
    (value: string) => {
      if (value === 'current') {
        setCurrentWeek(new Date());
      } else if (value === 'previous') {
        setCurrentWeek(subWeeks(currentWeek, 1));
      } else if (value === 'next') {
        setCurrentWeek(addWeeks(currentWeek, 1));
      }
    },
    [currentWeek],
  );

  return (
    <Pane display="flex" padding={16}>
      <Pane flex={1} alignItems="center" display="flex">
        <Heading size={600}>
          <span role="img" aria-label="Tyrannosaurus rex">
            🦖
          </span>{' '}
          T-Sheet
        </Heading>
      </Pane>
      <Pane display="flex" alignItems="center">
        <SearchInput
          height={40}
          placeholder="Search logs..."
          marginRight={16}
        />

        <IconButton
          icon={ChevronLeftIcon}
          height={40}
          onClick={() => onWeekChange('previous')}
        />
        <Button height={40} onClick={() => onWeekChange('current')}>
          Current week
        </Button>
        <IconButton
          icon={ChevronRightIcon}
          height={40}
          marginRight={16}
          onClick={() => onWeekChange('next')}
        />

        <AmplifySignOut />
      </Pane>
    </Pane>
  );
};

export default Header;
