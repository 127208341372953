import React, { useEffect, FC, useCallback, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Pane, Text, Card, TextInput, Heading } from 'evergreen-ui';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  format,
  formatISO,
} from 'date-fns';

import { listEntrys } from '../graphql/queries';
import EntryCard from './EntryCard';
import { NewEntryData } from './Detail';

const Week: FC<{
  current: Date;
  openExisting: (id: string) => void;
  startNew: (data: NewEntryData) => void;
}> = ({ current, openExisting, startNew }) => {
  useEffect(() => {
    (async () => {
      /*const newLog = await API.graphql(
        graphqlOperation(createLog, {
          input: {
            title: "Hmmm",
            time: 5,
            date: "2020-08-04",
            projectID: "b2cac84b-52c4-47e5-bc92-fa0f5eaafed0",
          },
        } as CreateLogMutationVariables)
      );
      console.log(newLog);*/

      const allEntries = await API.graphql(graphqlOperation(listEntrys));
      console.log(allEntries);
    })();
  }, []);
  const onDragEnd = useCallback(e => {
    console.log(e);
  }, []);

  const days = eachDayOfInterval({
    start: startOfWeek(current),
    end: endOfWeek(current),
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Pane display="flex" padding={8} flex="1" justifyContent="stretch">
        {days.map((day, index) => (
          <Droppable key={index} droppableId={`day-${day.toISOString()}`}>
            {(provided, snapshot) => (
              <Pane
                ref={provided.innerRef}
                display="flex"
                flexDirection="column"
                flex="1"
                background={
                  snapshot.isDraggingOver
                    ? 'greenTint'
                    : index === 5 || index === 6
                    ? 'tealTint'
                    : index % 2 === 1
                    ? 'tint1'
                    : 'tint2'
                }
                padding={8}
                margin={4}
              >
                <Pane
                  display="flex"
                  marginBottom={8}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Heading>{format(day, 'ccc d LLL')}</Heading>

                  <Text>0h</Text>
                </Pane>

                <Draggable draggableId={`day-${index}-test`} index={0}>
                  {(provided, snapshot) => (
                    <EntryCard
                      provided={provided}
                      snapshot={snapshot}
                      onClick={() => openExisting('test')}
                    />
                  )}
                </Draggable>

                {provided.placeholder}

                <TextInput
                  width="100%"
                  name={`new-entry-day-${day.getDate()}`}
                  placeholder="Add a new entry..."
                  onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    e.key === 'Enter' &&
                    startNew({
                      title: (e.target as HTMLInputElement).value,
                      date: formatISO(day, { representation: 'date' }),
                    })
                  }
                  onClick={(
                    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
                  ) => e.stopPropagation()}
                />
              </Pane>
            )}
          </Droppable>
        ))}
      </Pane>
    </DragDropContext>
  );
};

export default Week;
